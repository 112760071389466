@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
	--backColor: #76717f;
	--secondary: #47444d;
	--backColorv2: #635480;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Poppins', sans-serif;
	margin: 2.2rem 0;
	font-weight: 700;
	letter-spacing: 1.5px;
}
body,
html {
	height: 100%;
	font-size: 10px;
	font-family: 'Poppins', sans-serif;
}
body {
	background-image: url(../img/bit-pattern.png);
}

.w3-content {
	max-width: 1300px;
}

p,
li,
button {
	font-size: 1.7rem;
	line-height: 2.9rem;
}
img {
	cursor: default !important;
}
.max_width {
	max-width: 100%;
}
.text-center {
	text-align: center;
}
.container {
	width: auto;
	margin: 0 auto;
	display: block;
}
@media only screen and (min-width: 768px) {
	.container {
		width: 750px;
	}
}
@media only screen and (min-width: 992px) {
	.container {
		width: 970px;
	}
}
@media only screen and (min-width: 1400px) {
	.container {
		width: 1170px;
	}
}
.bcg_asphalt {
	background-color: #34495e;
}
.bcg_bitblue {
	background-color: #283440;
}
.bcg_clouds {
	background-color: #ecf0f1;
}
.bcg_midnight {
	background-color: #2c3e50;
}
.bcg_see {
	background-color: #16a085;
}
.col_asphalt {
	color: #34495e;
}
.col_belize {
	color: #2d5e89;
}
.col_clouds {
	color: #ecf0f1;
}
.col_midnight {
	color: #2c3e50;
}
.col_see {
	color: #16a085;
}
.col_bitblue {
	color: #2d5e89;
}
.quicksand {
	/* font-family: Exo, sans-serif; */
	text-align: center;
}
.modal_max {
	max-height: 80%;
	overflow: auto;
}
.h_min300 {
	min-height: 300px;
}
.h_min480 {
	min-height: 480px;
}
.h_min510 {
	min-height: 510px;
}
.h_min570 {
	min-height: 570px;
}
.h_min630 {
	min-height: 630px;
}
.h_min660 {
	min-height: 660px;
}
.w30 {
	width: 30px;
}
.mySlides {
	display: none;
}
.w3-left,
.w3-right,
.w3-tag {
	cursor: pointer;
}
.w3-tag {
	height: 15px;
	width: 15px;
	padding: 0;
	margin-top: 6px;
}
ul.topnav {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}
ul.topnav li {
	float: left;
}
ul.topnav li a {
	display: inline-block;
	color: #f2f2f2;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	transition: 0.3s;
	font-size: 17px;
}
ul.topnav li a:hover {
	background-color: #111;
}
ul.topnav li.icon {
	display: none;
}
@media screen and (max-width: 750px) {
	ul.topnav li:not(:first-child) {
		display: none;
	}
	ul.topnav li.icon {
		/* float: right; */
		display: inline-block;
	}
}
@media screen and (max-width: 750px) {
	ul.topnav.responsive {
		position: relative;
	}
	ul.topnav.responsive li.icon {
		position: absolute;
		right: 0;
		top: 0;
	}
	ul.topnav.responsive li {
		float: none;
		display: inline;
	}
	ul.topnav.responsive li a {
		display: block;
		text-align: left;
	}
}
.contact-outer {
	padding: 20px 0;
	max-width: 525px;
	margin: 0 auto;
}
.contact {
	padding: 10px 30px;
}
.contact p {
	margin: 5px auto;
}
.bgimg-1 {
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

@media (max-width: 1024px) {
	.bgimg-1 {
		background-attachment: scroll;
	}
}
.bgimg-1 {
	background-image: url(../img/bit-parallax.jpg);
	min-height: 100%;
	/* font-family: Exo, sans-serif; */
}

#navibar {
	z-index: 2000;
}
.sectionAnchor {
	height: 0;
}
#company {
	padding-top: 60px;
}

#offer {
	background: url(../img/prod_bcg.png) top no-repeat;
	padding-top: 110px;
}
.img-responsive {
	max-width: 100%;
	height: auto;
	display: block;
}
#googleMap {
	width: 100%;
	height: 400px;
	filter: grayscale(90%);
	-webkit-filter: grayscale(60%);
}
#team {
	margin: 0 auto 70px;
}
#team .team-desc {
	background-color: #fff;
}
.cookies_page .w3-container ol li,
.privacy_policy_page .w3-container ol li {
	margin: 8px auto;
}
.topnav .img-wrapper {
	line-height: 54px;
}
.topnav .img-wrapper img {
	max-height: 25px;
	margin-left: 10px;
}
.top-margin {
	margin-top: 80px;
}
footer.footer-main {
	margin-top: 30px;
	position: relative;
}

.footerContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 3rem;
}

ul.privacy_cookies {
	list-style-type: none;
	padding-left: 0;
	margin-left: 0;
	margin-top: 2rem;
	color: #626262;
}
ul.privacy_cookies li {
	display: inline-block;
	margin-right: 10px;
}
ul.privacy_cookies li a {
	text-decoration: none;
	font-weight: 700;
}
ul.privacy_cookies li a:hover {
	text-decoration: underline;
}
#policy-popup {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0.9;
	width: 100%;
	background-color: #f6f6f6;
	display: none;
}
#policy-popup .popup-inner {
	padding: 20px;
	background-color: #f6f6f6;
}
#policy-popup .popup-inner a {
	color: #34495e;
}
#policy-popup .popup-inner a:hover {
	color: #46627f;
}
#policy-popup .popup-inner button {
	margin-top: 10px;
	padding: 5px 15px;
	border: 0;
	border-radius: 0;
	color: #fff;
	background-color: #34495e;
}
#policy-popup .popup-inner button:hover {
	background-color: #ffc107;
	color: #000;
}

.careerContainer {
	padding: 3rem 0;
	background-color: #fbfbfb;
}
